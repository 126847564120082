import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'

// initial state
const state = {
  business: null,
  businessSettingOptions: [],
  businessSettings: [],
  businessUser: null,
  businessUsers: null,
  list: null,
  discountCodes: [],
  invoices: [],
  paymentOptions: [],
  BusinessPaymentOptions: []
}

// getters
const getters = {
  business: state => state.business,
  businessSettingOptions: state => state.businessSettingOptions,
  businessSettings: state => state.businessSettings,
  businessUser: state => state.businessUser,
  businessUsers: state => state.businessUsers,
  businessList: state => state.list,
  discountCodes: state => state.discountCodes,
  invoices: state => state.invoices,
  paymentOptions: state => state.paymentOptions
}

// actions
const actions = {
  // business
  getBusinesses ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('/businesses' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.BUSINESSES_DATA, resp.data["hydra:member"])
    })
  },
  getBusiness ({ commit }, params) {
    return HTTP.get('/businesses' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.BUSINESS_DATA, resp.data["hydra:member"][0])
      return resp.data["hydra:member"][0]
    }, error => {
      console.log('could not retrieve user list')
    })
  },
  postBusiness ({ commit }, params) {
    return HTTP.post('/businesses' + DOT_EXT, params).then(resp => {
      commit(types.BUSINESS_DATA, resp["hydra:member"])
      return resp
    }, error => {
      console.log(error)
    })
  },
  putBusiness ({ commit }, params) {
    return HTTP.put('/businesses/'+params.id + DOT_EXT, params).then(resp => {
      commit(types.BUSINESS_DATA, resp.data)
      return resp
    }, error => {
      console.log(error)
    })
  },
  resetBusinesses ({ commit }) {
    commit(types.BUSINESSES_DATA, [])
  },
  resetBusiness ({ commit }) {
    commit(types.BUSINESS_DATA, {})
  },

  // business settings
  getBusinessSettingOptions ({ commit }) {
    return HTTP.get('/business_setting_options' + DOT_EXT, {
      params: {
        'order[name]': 'asc',
        'pagination': false,
        'archived': false
      }
    }).then(resp => {
      commit(types.SET_BUSINESS_SETTING_OPTIONS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  getBusinessSettings ({ commit }, params) {
    return HTTP.get('/business_settings' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_BUSINESS_SETTINGS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postBusinessSetting ({ commit }, params) {
    return HTTP.post('/business_settings' + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },
  putBusinessSetting ({ commit }, params) {
    return HTTP.put('/business_settings/' + params.id + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },

  // coverage
  getCoverages ({ commit }, params) {
    return HTTP.get('/business_coverages' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"]
    })
  },
  getCoverage ({ commit }, params) {
    return HTTP.get('/business_coverages' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    }, error => {
      console.log(error)
    })
  },
  postCoverage ({ commit }, params) {
    return HTTP.post('/business_coverages' + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },
  deleteCoverage ({ commit }, coverageId) {
    return HTTP.delete('/business_coverages/' + coverageId).then(resp => {
      // 
    }, error => {
      console.log(error)
    })
  },
  putCoverage ({ commit }, params) {
    return HTTP.put('/business_coverages/' + params.id + DOT_EXT, params).then(resp => {
      return resp
    })
  },

  // business aliases
  getAliases ({ commit }, params) {
    return HTTP.get('/business_aliases' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  postAlias ({ commit }, params) {
    return HTTP.post('/business_aliases' + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },
  deleteAlias ({ commit }, aliasId) {
    return HTTP.delete('/business_aliases/' + aliasId).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },

  // discount codes
  postDiscountCode ({ commit }, params) {
    return HTTP.post('/discount_codes' + DOT_EXT, params).then(resp => {
      commit(types.ADD_DISCOUNT_CODE, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  getDiscountCodes ({ commit }, params) {
    return HTTP.get('/discount_codes' + DOT_EXT, { params }).then(resp => {
      commit(types.SET_DISCOUNT_CODES, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  getDiscountCode ({ commit }, params) {
    return HTTP.get('/discount_codes' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    }, error => {
      console.log(error)
    })
  },
  putDiscountCode ({ commit }, params) {
    return HTTP.put('/discount_codes/' + params.id + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },
  resetDiscountCodes ({ commit }) {
    commit(types.SET_DISCOUNT_CODES, [])
  },

  // business revenue
  getBusinessRevenue (_, params) {
    return HTTP.get('/business_revenues', { params } ).then(resp => {
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },

  // invoices
  postInvoice ({ commit }, params) {
    return HTTP.post('/invoices' + DOT_EXT, params).then(resp => {
      commit(types.ADD_INVOICE, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  getInvoices ({ commit }, params) {
    return HTTP.get('/invoices' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_INVOICES, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  getInvoice ({ commit }, params) {
    return HTTP.get('/invoices' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    }, error => {
      console.log(error)
    })
  },
  putInvoice ({ commit }, params) {
    return HTTP.put('/invoices/'+params.id + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },
  deleteInvoice ({ commit }, id) {
    return HTTP.delete('/invoices/'+id)
  },
  terminateInvoice ({ commit }, id) {
    return HTTP.delete('/invoices/' + id + '/terminate')
  },
  resetInvoices ({ commit }) {
    commit(types.SET_INVOICES, [])
  },
  getInvoiceItems ({ commit }, params) {
    return HTTP.get('/invoice_items' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  postInvoiceItem ({ commit }, params) {
    return HTTP.post('/invoice_items' + DOT_EXT, params).then(resp => {
      // commit(types.ADD_INVOICE_ITEM, resp["hydra:member"])
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putInvoiceItem ({ commit }, params) {
    return HTTP.put('/invoice_items/'+params.id + DOT_EXT, params).then(resp => {
      return resp.data
    })
  },
  deleteInvoiceItem (_, id) {
    return HTTP.delete('/invoice_items/' + id + DOT_EXT)
  },

  // corporate payment options
  postPaymentOptions ({ commit }, params) {
    return HTTP.post('/payment_options' + DOT_EXT, params).then(resp => {
      commit(types.ADD_PAYMENT_OPTION, resp["hydra:member"])
      return resp["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  getPaymentOptions ({ commit }, params) {
    return HTTP.get('/payment_options' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_PAYMENT_OPTIONS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },

  // business payment options
  getBusinessPaymentOptions ({ commit }, params) {
    return HTTP.get('/business_payment_options' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_BUSINESS_PAYMENT_OPTIONS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  postBusinessPaymentOptions ({ commit }, params) {
    return HTTP.post('/business_payment_options' + DOT_EXT, params).then(resp => {
      commit(types.ADD_BUSINESS_PAYMENT_OPTION, resp["hydra:member"])
      return resp["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  putBusinessPaymentOptions ({ commit }, params) {
    return HTTP.put('/business_payment_options/' + params.id + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  },
  resetBusinessPaymentOptions ({ commit }) {
    commit(types.SET_BUSINESS_PAYMENT_OPTIONS, [])
  },

  /* applicants */
  getApplicant ({ commit }, id) {
    return this.$axios.get('/applicants/' + id).then(resp => {
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putApplicant ({ commit }, params) {
    return HTTP.put('/applicants/' + params.id + DOT_EXT, params).then(resp => {
      return resp
    }, error => {
      console.log(error)
    })
  }
}

// mutations
const mutations = {
  [types.BUSINESSES_DATA] (state, data) {
    state.list = data
  },
  [types.BUSINESS_DATA] (state, data) {
    state.business = data
  },
  [types.SET_DISCOUNT_CODES] (state, data) {
    state.discountCodes = data
  },
  [types.ADD_DISCOUNT_CODE] (state, data) {
    state.discountCodes.push(data)
  },
  [types.SET_INVOICES] (state, data) {
    state.invoices = data
  },
  [types.ADD_INVOICE] (state, data) {
    state.invoices.push(data)
  },
  [types.SET_PAYMENT_OPTIONS] (state, data) {
    state.paymentOptions = data
  },
  [types.ADD_PAYMENT_OPTION] (state, data) {
    state.paymentOptions.push(data)
  },
  [types.SET_BUSINESS_PAYMENT_OPTIONS] (state, data) {
    state.businessPaymentOptions = data
  },
  [types.ADD_BUSINESS_PAYMENT_OPTION] (state, data) {
    state.businessPaymentOptions.push(data)
  },
  [types.SET_BUSINESS_SETTING_OPTIONS] (state, data) {
    state.businessSettingOptions = data
  },
  [types.SET_BUSINESS_SETTINGS] (state, data) {
    state.businessSettings = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
