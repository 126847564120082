import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const state = {
  reportRevenue: null,
  reportsRevenue: [],
  reportMarketing: {},
  reportsMarketing: []
}

const getters = {
  reportRevenue: state => state.reportRevenue,
  reportsRevenue: state => state.reportsRevenue,
  reportMarketing: state => state.reportMarketing,
  reportsMarketing: state => state.reportsMarketing
}

const actions = {
  // revenue
  getReportsRevenue ({ commit }, params) {
    return HTTP.get('/reports_revenues', {
      params: params
    }).then(resp => {
      if (!params.id) {
        commit(types.SET_REPORTS_REVENUE, resp.data["hydra:member"])
        return resp.data["hydra:member"]
      } else {
        commit(types.SET_REPORT_REVENUE, resp.data["hydra:member"][0])
        return resp.data["hydra:member"][0]
      }
    })
  },
  postReportsRevenue ({ commit }, data) {
    return HTTP.post('/reports_revenues', data).then(resp => {
      commit(types.ADD_REPORT_REVENUE, resp.data)
      return resp.data
    })
  },
  deleteReportsRevenue ({ commit }, id) {
    return HTTP.delete('/reports_revenues/' + id)
  },
  putReportsRevenue ({ commit }, data) {
    return HTTP.put('/reports_revenues/' + data.id, data).then(resp => {
      commit(types.ADD_REPORT_REVENUE, resp.data)
    })
  },
  resetReport ({ commit }) {
    commit(types.SET_REPORT_REVENUE, null)
  },
  resetReports ({ commit }) {
    commit(types.SET_REPORT_REVENUE, null)
    commit(types.SET_REPORTS_REVENUE, [])
  },
  //marketing
  getReportsMarketing ({ commit }, params) {
    let id = params.id ? '/' + params.id : ''
    return HTTP.get('/reports_marketings' + id, {
      params: params
    }).then(resp => {
      if (id === '') {
        commit(types.SET_REPORTS_MARKETING, resp.data["hydra:member"])
        return resp.data["hydra:member"]
      } else {
        commit(types.SET_REPORT_MARKETING, resp.data)
        return resp.data
      }
    })
  },
  postReportsMarketing ({ commit }, data) {
    return HTTP.post('/reports_marketings', data).then(resp => {
      commit(types.ADD_REPORT_MARKETING, resp.data)
      return resp.data
    })
  },
  // there are no get functions for this as they are included in the report response
  postReportMarketingSources ({ commit }, data) {
    return HTTP.post('/reports_marketing_sources', data).then(resp => {
      return resp.data
    })
  },
  deleteReportsMarketing ({ commit }, id) {
    return HTTP.delete('/reports_marketings/' + id)
  },
  putReportsMarketing ({ commit }, data) {
    return HTTP.put('/reports_marketings/' + data.id, data).then(resp => {
      commit(types.ADD_REPORT_MARKETING, resp.data)
    })
  },
  resetMarketing ({ commit }) {
    commit(types.SET_REPORT_MARKETING, {})
    commit(types.SET_REPORTS_MARKETING, [])
  }
}

const mutations = {
  [types.SET_REPORT_REVENUE] (state, data) {
    state.reportRevenue = data
  },
  [types.SET_REPORTS_REVENUE] (state, data) {
    state.reportsRevenue = data
  },
  [types.ADD_REPORT_REVENUE] (state, data) {
    state.reportsRevenue.push(data)
  },
  [types.SET_REPORT_MARKETING] (state, data) {
    state.reportMarketing = data
  },
  [types.SET_REPORTS_MARKETING] (state, data) {
    state.reportsMarketing = data
  },
  [types.ADD_REPORT_MARKETING] (state, data) {
    state.reportsMarketing.push(data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}